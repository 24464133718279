import React, { useState, useEffect } from "react";
import Banner from "../components/Category/Banner";
import ListPaginate from "../components/ListPaginate";
import Sidebar from "../components/Category/Sidebar";
import apiHelper from "../api/apiHelper";

const samplecategories = [
  "Current Events",
  "Upcoming Events",
  "Ended Events",
  "Science",
  "Social",
  "Politics",
  "Environment",
  "Communicative",
  "Mathematics",
];

function Events() {
  const [categories, setCategories] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const getEventsList = async () => {
      await apiHelper
        .axiosCallGet(
          `${process.env.REACT_APP_BASE_URL}/api/?action=listEvents`
        )
        .then((response) => {
          console.log(response);
          setEventsList(response);
          setCategories(samplecategories);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getEventsList();
  }, []);

  const scrollToHumans = () => {
    document.getElementById("human-mobile").scrollIntoView({
      behavior: "smooth",
    });
    document.getElementById("human-desktop").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <Banner scrollToHumans={scrollToHumans} />

      <div className="w-full justify-center flex lg:hidden">
        <div id="human-mobile" className="w-[87%] mt-[50px]">
          <Sidebar data={categories} value={selected} setValue={setSelected} />
          <ListPaginate renderCard="event" list={eventsList} />
        </div>
      </div>

      <div className="w-full justify-center hidden lg:flex">
        <div id="human-desktop" className="w-[87%] flex">
          <div className="w-1/4">
            <Sidebar
              data={categories}
              value={selected}
              setValue={setSelected}
            />
          </div>
          <div className="w-3/4">
            <ListPaginate renderCard="event" list={eventsList} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Events;
