/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Logo from "./Logo";

function Footer() {
  return (
    <footer className="w-full flex justify-center mt-[100px] mb-[50px]">
      <div className="w-[87%]">
        <div className="flex justify-between flex-col lg:flex-row">
          <div className="mb-6 lg:mb-0">
            <a href="#">
              <Logo />
              <p className="text-[#B8B8B8] text-[14px] text-left w-[300px] mt-[22px] hidden">
                Maecenas dignissim justo eget nulla rutrum molestie. Maecenas
                lobortis sem dui, vel rutrum risus tincidunt ullamcorper. Proin
                eu enim metus. Vivamus sed libero ornare, tristique quam in,
                gravida enim.
              </p>
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 hidden">
            <div>
              <h2 className="mb-6 text-sm font-avenir-black font-semibold text-white text-left text-base">
                Company
              </h2>
              <ul className="text-gray-600 dark:text-gray-400 mb-[47px] ">
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    About
                  </a>
                </li>
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Careers
                  </a>
                </li>
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Press
                  </a>
                </li>
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-avenir-black font-semibold text-white text-left text-base">
                Community
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Team Plans
                  </a>
                </li>
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Refer a Friend
                  </a>
                </li>
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Limited Memberships
                  </a>
                </li>
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Scholarships
                  </a>
                </li>
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Free Classes
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-avenir-black font-semibold text-white text-left text-base">
                Teaching
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Become a Teacher
                  </a>
                </li>{" "}
                <li className="mb-[10px] text-left">
                  <a
                    href="#"
                    className="hover:underline text-[#B8B8B8] text-[16px] font-normal"
                  >
                    Teacher Help Center
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-[#DF5B04] sm:mx-auto lg:my-8 opacity-25" />
        <div className="flex flex-col-reverse lg:flex-row lg:items-center justify-between">
          <span className="text-sm text-gray-500 lg:text-center dark:text-gray-400">
            ©{" "}
             
              <span className="font-bold">Speaking</span> Library - House of Wisdom{" "}
             
            2022
          </span>
          <div className="flex mt-4 space-x-12 lg:justify-center sm:mt-0 lg:mb-0 mb-[40px] hidden">
            <a href="#" className="text-white">
              Help
            </a>
            <a href="#" className="text-white">
              Privacy
            </a>
            <a href="#" className="text-white">
              Terms
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
