import { useState, useRef, useEffect } from "react";
import teacher1 from "../../assets/teacher1.png";
import teacher2 from "../../assets/teacher2.png";
import teacher3 from "../../assets/teacher3.png";
import teacher4 from "../../assets/teacher4.png";
import video from "../../assets/video.png";
import mic from "../../assets/mic.png";
import { Link } from "react-router-dom";
import leftslide from "../../assets/leftslide.png";
import arrowLeft from "../../assets/Arrow_drop_right.png";
import rightslide from "../../assets/rightslide.png";
import filter from "../../assets/filter.png";
import { useNavigate } from "react-router-dom";
import cardvideo from "../../assets/cardvideo.mp4";
import Slider from "../Carousel/lib";
// import "../Carousel/lib/carousel/style.css";
// Data

const samplecategories = [
  "All Categories",
  "Animation",
  "Design",
  "Illustration",
  "Lifestyle",
  "Photo & Film",
  "Business",
  "Writing",
];

const sampledata = [
  {
    title: "Jessica Hische",
    by: "Illustrator",
    link: "#",
    imageUrl: teacher1,
  },
  {
    title: "Jessica Hische",
    link: "#",
    by: "Illustrator",
    imageUrl: teacher2,
  },
  {
    title: "Jessica Hische",
    link: "#",
    by: "Illustrator",
    imageUrl: teacher3,
  },
  {
    title: "Jessica Hische",
    link: "#",
    imageUrl: teacher4,
    by: "Illustrator",
  },
  {
    title: "Jessica Hische",
    link: "#",
    by: "Illustrator",
    imageUrl: teacher1,
  },
  {
    title: "Jessica Hische",
    link: "#",
    by: "Illustrator",
    imageUrl: teacher3,
  },
  {
    title: "Jessica Hische",
    link: "#",
    by: "Illustrator",

    imageUrl: teacher2,
  },
  {
    title: "Jessica Hische",
    link: "#",
    by: "Illustrator",
    imageUrl: teacher4,
  },
];

const TeacherCarousel = ({ humansList }) => {
  const itemRef = useRef([]);
  const [data, setData] = useState([]);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(0);
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);
  const navigate = useNavigate();

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === "prev") {
      return currentIndex <= 0;
    }

    if (direction === "next" && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  useEffect(() => {
    setData(humansList);
    setCategories(samplecategories);
  }, [humansList]);

  useEffect(() => {
    const currentTab = itemRef.current[selected];
    console.log(currentTab);

    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
  }, [data, selected]);

  return (
    <div className="carousel my-12 mx-auto mt-[60px] md:mt-[70px]">
      <div className="flex items-center flex-col">
        <div className="flex justify-between items-center w-[87%] md:block">
          <h2 className="text-white text-[24px] md:text-[40px] font-avenir-black font-bold mb-[50px] md:text-center md:w-full w-2/2">
            A Library of Speaking Books
          </h2>
          <button
            className="text-[#B8B8B8] whitespace-nowrap font-semibold items-end font-sans flex hidden"
            onClick={() => {
              navigate("/category");
            }}
          >
            View More <img src={arrowLeft} alt="arrow" className="ml-[10px]" />
          </button>
        </div>

        <div className="mb-[25px] flex justify-center items-center w-[87%] hidden">
          <img src={filter} alt="filter" className="w-[54px] h-[54px]" />
          <div className="flex justify-between items-center w-full mx-[30px] overflow-scroll">
            <div
              className={`relative flex gap-[30px] items-center ${
                categories.length < 1 && "animate-pulse"
              }`}
            >
              {categories.length > 0
                ? categories.map((category, i) => (
                    <div
                      ref={(el) => (itemRef.current[i] = el)}
                      className="flex flex-col cursor-pointer mb-2"
                      onClick={() => {
                        setSelected(i);
                      }}
                    >
                      <p
                        className={`text-[16px] font-sans whitespace-nowrap font-medium transition-all duration-300 ${
                          categories[selected] === category
                            ? "text-[#DF5B04]"
                            : "text-[#B8B8B8]"
                        }`}
                      >
                        {category}
                      </p>
                    </div>
                  ))
                : [...Array(8).keys()].map(() => (
                    <div className="w-[100px] h-[16px] bg-[#202020]"></div>
                  ))}
              <span
                className={`absolute block h-1 bg-[#DF5B04] border-[#DF5B04] border-[2px] bottom-0 transition-all duration-300 !w-[25px] ${
                  categories.length < 1 && "hidden"
                }`}
                style={{ left: tabUnderlineLeft }}
              />
            </div>
          </div>
          <button
            className="bg-[#DF5B04] whitespace-nowrap hover:bg-[#222222] transition-colors text-white font-semibold items-center px-[2rem] min-h-[4rem] font-sans hidden md:flex"
            onClick={() => {
              navigate(`/events/${process.env.REACT_APP_HIGHLIGHT_EVENT}`);
            }}
          >
            View More <img src={arrowLeft} alt="arrow" className="ml-[10px]" />
          </button>
        </div>
      </div>

      <div className="relative overflow-hidden flex justify-center">
        {/* <div className="flex justify-between absolute top left w-[87%] h-full">
          <button
            onClick={movePrev}
            className="text-white w-10 h-full text-center z-10 p-0 ml-[-15px] transition-all ease-in-out duration-300"
          >
            <img src={leftslide} alt="leftslide" />
          </button>
          <button
            onClick={moveNext}
            className="text-white w-10 h-full text-center z-10 p-0 mr-[-15px] transition-all ease-in-out duration-300"
          >
            <img src={rightslide} alt="rightslide" />
          </button>
        </div> */}
        {/* <div
          ref={carousel}
          className={`carousel-container relative flex gap-[24px] overflow-y-hidden overflow-x-scroll scroll-smooth md:snap-x md:snap-mandatory md:touch-pan-x z-0 w-[87%] ${
            data.length < 1 && "animate-pulse"
          }`}
        > */}
        <Slider
          slidesToShow={4}
          wheelScroll={1}
          wheel
          // shift={25}
          className={`h-[320px] w-[87%] gap-[24px] relative ${
            data.length < 1 && "animate-pulse"
          }`}
          nextArrow={
            <button
              onClick={moveNext}
              className="text-white w-10 text-center z-10 p-0 mr-[-15px] transition-all ease-in-out duration-300"
            >
              <img src={rightslide} alt="rightslide" />
            </button>
          }
          prevArrow={
            <button
              onClick={movePrev}
              className="text-white w-10 text-center z-10 p-0 ml-[-15px] transition-all ease-in-out duration-300"
            >
              <img src={leftslide} alt="leftslide" />
            </button>
          }
        >
          {data.length > 0
            ? data.map((resource, index) => {
                return (
                  <div key={index} className="w-[288px] h-[320px]">
                    <Link
                      to={`/events/${process.env.REACT_APP_HIGHLIGHT_EVENT}/${resource.slug}`}
                      className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0"
                      style={{
                        backgroundImage: `url(${`${process.env.REACT_APP_BASE_URL}/${resource.img}`})`,
                      }}
                    >
                      {/* {index === 2 && (
                        <video
                          src={cardvideo}
                          className="absolute top-0 left-0 h-full w-[288px] object-cover"
                          muted
                          autoPlay
                          loop
                        ></video>
                      )} */}
                      <div className="top-0 absolute left-0 w-full h-full bg-gradient-to-t from-[#1B1C20]"></div>
                      <div className="bottom-0 absolute text-white text-[18px] font-bold text-start px-[25px] py-[25px]">
                        <h2 className="text-[32px] font-bold max-w-[150px]">
                          {resource.name}
                        </h2>
                        {/* {resource.categories && (
                          <h2 className="text-[#B8B8B8] font-semibold text-[18px]">
                            {resource.categories[0]}
                          </h2>
                        )} */}
                        {resource.tagline && (
                          <h2 className="text-[#B8B8B8] font-semibold text-[18px]">
                            {resource.tagline}
                          </h2>
                        )}
                      </div>
                    </Link>
                  </div>
                );
              })
            : [...Array(2).keys()].map((index) => (
                <div key={index} className="w-[288px] h-[320px] bg-[#202020]">
                  <div className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0">
                    <div className="top-0 absolute left-0 w-full h-full"></div>
                  </div>
                </div>
              ))}
        </Slider>
        {/* {data.length > 0
            ? data.map((resource, index) => {
                return (
                  <div
                    key={index}
                    className="carousel-item relative w-[288px] h-[320px] snap-start "
                  >
                    <a
                      href={resource.link}
                      className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0"
                      style={{
                        backgroundImage: `url(${resource.imageUrl || ""})`,
                      }}
                    >
                      {index === 2 && (
                        <video
                          src={cardvideo}
                          className="absolute top-0 left-0 h-full w-full object-cover"
                          muted
                          autoPlay
                          loop
                        ></video>
                      )}
                      <div className="top-0 absolute left-0 w-full h-full bg-gradient-to-t from-[#1B1C20]"></div>
                      <div className="bottom-0 absolute text-white text-[18px] font-bold text-start px-[25px] py-[25px]">
                        <h2 className="text-[32px] font-bold max-w-[100px]">
                          {resource.title}
                        </h2>
                        <h2 className="text-[#B8B8B8] font-semibold text-[18px]">
                          {resource.by}
                        </h2>
                      </div>
                    </a>
                  </div>
                );
              })
            : [...Array(15).keys()].map((index) => (
                <div
                  key={index}
                  className="carousel-item relative w-[288px] h-[320px] snap-start bg-[#202020]"
                >
                  <div className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0">
                    <div className="top-0 absolute left-0 w-full h-full"></div>
                  </div>
                </div>
              ))} */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default TeacherCarousel;
