import React from "react";
import location from "../../assets/svg/location.svg";
import ticket from "../../assets/svg/ticket.svg";
import { Link } from "react-router-dom";

function OrderCard({ data }) {
  return (
    <div className="relative w-full flex flex-col justify-evenly bg-[#222222]">
      <div
        className="h-full w-full block z-0"
        // style={{
        //   backgroundImage: `url(${data.img || ""})`,
        // }}
      >
        <div className="h-[175px] relative w-full">
          <img
            src={`https://i.pravatar.cc/300?i=${Math.random()}`}
            alt=""
            className="object-cover w-full h-[175px]"
          />

          {Math.random() > Math.random() ? (
            <span class="absolute right-0 top-[10px] bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">
              Pending
            </span>
          ) : (
            <span class="absolute right-0 top-[10px] bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
              Paid
            </span>
          )}
        </div>

        {/* <div className="top-0 absolute left-0 w-full h-[175px] bg-gradient-to-t from-[#1B1C20] to-[#222222] opacity-30"></div> */}
        <div className="top-0 absolute left-0 w-full h-[175px] bg-gradient-to-t from-[#1B1C20]"></div>

        <div className="text-white text-[18px] font-bold text-start p-[15px] w-full">
          <div className="flex flex-col justify-between h-full w-full">
            <div>
              <h2 className="text-[16px] text-white font-bold mb-[10px] w-2/3 md:w-full text-ellipsis overflow-hidden projecttitle">
                John Doe
              </h2>
              <h6 className="text-[#B8B8B8] font-medium text-[14px]">
                React Online Discussion with expert
              </h6>

              <div className="my-[20px]">
                <div className="flex items-center mb-[10px]">
                  <svg
                    class="w-5 h-5"
                    fill="none"
                    stroke="#B8B8B8"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                  <h6 className="ml-[10px] text-[#B8B8B8] font-medium text-[14px]">
                    {data + 1}-09-2022
                  </h6>
                </div>
                <div className="flex items-center">
                  <svg
                    class="w-5 h-5"
                    fill="none"
                    stroke="#B8B8B8"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <h6 className="ml-[10px] text-[#B8B8B8] font-medium text-[14px]">
                    11am to 12:30pm
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderCard;
