import React from "react";
import productbanner from "../../assets/productbanner.png";
import arrowLeft from "../../assets/Arrow_drop_right.png";

function Banner() {
  return (
    <div className="z-0">
      <section class="w-full pt-[110px] h-[550px]">
        <img
          src={productbanner}
          className="object-cover w-full h-[400px]"
          alt="Home bg"
        />
      </section>

      <section class="w-full h-[400px] top-[110px] absolute left-0 flex justify-center items-center  bg-[#121212] opacity-70"></section>
      <section class="w-1/2 h-[400px] top-[110px] absolute left-0 flex justify-center items-start flex-col px-[100px]">
        <h2 className="text-white text-[40px] font-bold">
          Your First day in Blender 3D
        </h2>
        <br />
        <h3 className="text-[#B8B8B8] text-[24px] mb-[40px] font-light">
          SouthernShotty3D, Motion: Design, Direction, & Animation
        </h3>
      </section>
    </div>
  );
}

export default Banner;
