import React, { useState } from "react";
import aboutperson from "../../assets/productman.png";
import arrowLeft from "../../assets/Arrow_drop_right.png";
import instagram from "../../assets/svg/Vectorinstagram.svg";
import github from "../../assets/svg/Vectorgithub.svg";
import discord from "../../assets/svg/Vectordiscord.svg";
import telegram from "../../assets/svg/Vectortelegram.svg";
import facebook from "../../assets/svg/Vectorfacebook.svg";
import twitter from "../../assets/svg/Vectortwitter.svg";
import moment from "moment/moment";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";

function About({ updateModal, humanDetails, disabledDates, event }) {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      console.log("swipe", isLeftSwipe ? "left" : "right");
      const findHumanIndex = event.humans.findIndex(
        (hum) => hum.slug === params.human_slug
      );
      var navigateTo;
      if (isLeftSwipe) {
        navigateTo =
          event.humans[
            findHumanIndex + 1 > event.humans.length - 1
              ? 0
              : findHumanIndex + 1
          ];
      } else {
        navigateTo =
          event.humans[
            findHumanIndex - 1 < 0
              ? event.humans.length - 1
              : findHumanIndex - 1
          ];
      }
      navigate(`/events/${event.slug}/${navigateTo.slug}`);
    }
  };

  return (
    <div
      className="flex justify-center pt-[104px] md:pt-0 font-opensans tracking-wider"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <div className="w-full md:w-[87%] bg-[#222222] md:p-[25px] h-[40%] flex flex-col md:flex-row">
        {humanDetails ? (
          <>
            <div className="md:mr-[30px] w-full md:w-[30%] h-[30%]">
              <div className="relative w-full h-[60%] md:h-[40%] xl:h-[60%]">
                {humanDetails?.img && (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${humanDetails.img}`}
                    alt="about"
                    className="h-full w-full object-cover object-top max-h-[450px]"
                  />
                )}
                {humanDetails?.hourly_price && (
                  <button className="bg-[#DF5B04] py-[8px] px-[15px] text-white font-semibold absolute bottom-[10px] right-[10px] flex items-end h-fit md:hidden">
                    AED {humanDetails.hourly_price} / Hour
                  </button>
                )}
              </div>
              <div className="border-[#313131] px-[30px] py-[20px] md:border-b-[1px] md:border-r-[1px] md:border-l-[1px] h-[10%] md:h-[10%] xl:h-[10%] flex flex-col">
                <div>
                  {humanDetails?.categories && (
                    <h4 className="text-[#B8B8B8] font-semibold text-[16px]">
                      {humanDetails.categories[0]}
                    </h4>
                  )}
                  {humanDetails?.name && (
                    <h2 className="text-[24px] font-bold text-white">
                      {humanDetails.name}
                    </h2>
                  )}
                  {humanDetails?.tagline && (
                    <p className="text-[16px] mt-[10px] text-[#B8B8B8] font-normal block md:hidden">
                      {humanDetails.tagline}
                    </p>
                  )}
                </div>
                <div>
                  <div className="flex flex-row md:flex-col xl:flex-row xl:items-end  md:items-start items-end my-[20px] justify-between">
                    {humanDetails?.expertise_level && (
                      <div className="flex flex-col items-start w-fit">
                        <h5 className="text-[20px] font-bold text-[#DF5B04]">
                          {humanDetails.expertise_level}
                        </h5>
                        <h6 className="text-[16px] text-[#B8B8B8]">
                          Expertise level
                        </h6>
                      </div>
                    )}

                    {/* <div className="flex flex-col items-start w-fit">
                  <h5 className="text-[20px] font-bold text-white">73</h5>
                  <h6 className="text-[16px] text-[#B8B8B8]">Students</h6>
                </div>

                <div className="flex flex-col items-start w-fit">
                  <h5 className="text-[20px] font-bold text-white">119</h5>
                  <h6 className="text-[16px] text-[#B8B8B8]">Hours</h6>
                </div> */}
                  </div>

                  {/* <div className="flex justify-between my-[30px] flex-wrap">
                <img
                  className="mx-0 my-0 lg:mx-0 lg:my-0 md:mx-[10px] md:my-[10px]"
                  src={twitter}
                  alt=""
                />
                <img
                  className="mx-0 my-0 lg:mx-0 lg:my-0 md:mx-[10px] md:my-[10px]"
                  src={telegram}
                  alt=""
                />
                <img
                  className="mx-0 my-0 lg:mx-0 lg:my-0 md:mx-[10px] md:my-[10px]"
                  src={instagram}
                  alt=""
                />
                <img
                  className="mx-0 my-0 lg:mx-0 lg:my-0 md:mx-[10px] md:my-[10px]"
                  src={github}
                  alt=""
                />
                <img
                  className="mx-0 my-0 lg:mx-0 lg:my-0 md:mx-[10px] md:my-[10px]"
                  src={facebook}
                  alt=""
                />
                <img
                  className="mx-0 my-0 lg:mx-0 lg:my-0 md:mx-[10px] md:my-[10px]"
                  src={discord}
                  alt=""
                />
              </div> */}
                  <hr className=" border-t-[1px] border-r-0  border-b-0  border-l-0 border-[#313131] md:hidden" />
                </div>
              </div>
            </div>
            <div className="flex flex-col p-[25px] md:p-0 w-full md:w-[70%]">
              <div>
                {/* <div className="flex h-fit">
              <h4 className="py-[8px] px-[18px] bg-[#DF5B04] text-[#fff] font-bold mr-4">
                112 Students
              </h4>
              <h4 className="py-[8px] px-[18px] bg-[#DF5B04] text-[#fff] font-bold">
                1h 10m
              </h4>
            </div> */}

                <div className="flex justify-between">
                  <div>
                    {humanDetails?.name && (
                      <h2 className="text-[24px] font-bold text-white">
                        {humanDetails.name}
                      </h2>
                    )}
                    {humanDetails?.tagline && (
                      <p className="text-[16px] mt-[10px] text-[#B8B8B8] font-normal">
                        {humanDetails.tagline}
                      </p>
                    )}
                  </div>
                  {humanDetails?.hourly_price && (
                    <button className="bg-[#DF5B04] py-[8px] px-[15px] text-white font-semibold md:flex items-end h-fit hidden">
                      AED {humanDetails.hourly_price} / Hour
                    </button>
                  )}
                </div>
                {humanDetails?.description && (
                  <div className="mt-[50px]">
                    <h2 className="text-[18px] font-semibold text-white mb-[10px]">
                      About
                    </h2>
                    <p className="text-[16px] text-[#B8B8B8] mb-[10px] leading-7">
                      {humanDetails.description}
                    </p>
                  </div>
                )}
              </div>
              <div
                className={`flex ${
                  humanDetails?.expertise_fields
                    ? "justify-between"
                    : "justify-end"
                } lg:items-end flex-col lg:flex-row mt-20`}
              >
                {humanDetails?.expertise_fields && (
                  <div>
                    <h4 className="font-bold text-[18px] text-white mt-[30px] lg:mt-0 mb-[15px] lg:mb-[20px]">
                      Language
                    </h4>

                    <div className="flex h-fit justify-between">
                      {humanDetails?.expertise_fields
                        .split(",")
                        .map((expertise) => (
                          <h4 className="py-[8px] px-[18px] bg-[#121212] text-[#B8B8B8] text-[14px] font-bold lg:mr-[20px]">
                            {expertise}
                          </h4>
                        ))}
                    </div>
                  </div>
                )}
                {humanDetails.allow_booking === "1" && (
                  <>
                    {isAuthenticated || event?.allow_guest_booking === "1" ? (
                      <>
                        {/* {moment(
                          moment(disabledDates.end).format("YYYY-MM-DD")
                        ).isSameOrAfter(
                          moment(new Date()).format("YYYY-MM-DD"),
                          "day"
                        ) ? ( */}
                        <button
                          className="bg-[#DF5B04] hover:bg-[#121212] transition-colors text-white font-semibold flex items-center px-[2rem] min-h-[4rem] h-fit mt-[30px] lg:mt-0 justify-center"
                          onClick={() => {
                            updateModal(true);
                          }}
                        >
                          Book Now
                          <img
                            src={arrowLeft}
                            alt="arrow"
                            className="ml-[10px]"
                          />
                        </button>
                        {/* ) : (
                          <h5 className="text-[20px] font-bold text-[#DF5B04]">
                            Event Closed
                          </h5>
                        )} */}
                      </>
                    ) : (
                      <button
                        className="bg-[#DF5B04] hover:bg-[#121212] transition-colors text-white font-semibold flex items-center px-[2rem] min-h-[4rem] h-fit mt-[30px] lg:mt-0 justify-center"
                        onClick={loginWithRedirect}
                      >
                        Login to Book Now
                        <img
                          src={arrowLeft}
                          alt="arrow"
                          className="ml-[10px]"
                        />
                      </button>
                    )}
                  </>
                )}
              </div>
              {humanDetails.notes && (
                <div className="mt-[40px] border-[1px] border-[#313131]">
                  <p className="text-[16px] text-[#B8B8B8] leading-7 p-[10px]">
                    {humanDetails.notes}
                  </p>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="md:w-[450px] md:h-[450px] bg-[#202020] w-full h-[300px] mb-4 md:mr-4"></div>
            <div className="md:w-full md:h-[300px] bg-[#202020] w-[100%] h-[500px]"></div>
          </>
        )}
      </div>
    </div>
  );
}

export default About;
