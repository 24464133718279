import React from "react";
import imagecont1 from "../../assets/imagecont1.png";

import imagecont2 from "../../assets/imagecont2.png";
import arrowLeft from "../../assets/Arrow_drop_right.png";

function ImageCont() {
  return (
    <div id="featured" className="w-full mt-[60px] lg:mt-[70px]">
      <div className="lg:w-[90%] flex items-center flex-col lg:flex-row">
        <div className="aspect-ratio w-full">
          <img src={imagecont1} alt="" className="w-full" />
        </div>
        <div className="p-[17px] md:pl-[50px] md:p-0 mt-[30px] lg:mt-0">
          <h2 className="text-[24px] md:text-[40px] text-white font-bold text-left font-avenir-black">
            {/* <span className="text-[#FB802E] "></span>  */}
            About House of Wisdom’s Speaking Library
          </h2>
          <p className="text-[16px] md:text-[24px] text-[#B8B8B8] text-left mt-[25px]">
            We learn from books, media, and institutions — but we also learn
            from each other.
            <br />
            That’s why House of Wisdom is creating a space for the public to
            converse one-on-one with some of the world's most interesting
            people; those who have had life-changing experiences, driven
            influential causes or made a noticeable impact in their field of
            work.
            <br />
            Our aim is to not only create an online archive of thought-provoking
            conversations that contain unique stories, opinions, and advice, but
            to also stimulate new ideas and perspectives from these
            conversations that can alter our lives for the better.
          </p>
          {/* <button className="bg-[#DF5B04] hover:bg-[#222222] transition-colors text-white font-semibold flex items-center px-[2rem] min-h-[4rem] mt-[40px] md:mt-[60px]">
            Browse Classes{" "}
            <img src={arrowLeft} alt="arrow" className="ml-[10px]" />
          </button> */}
        </div>
      </div>
      {/* <div className="flex w-full justify-end">
        <div className="w-[90%] flex items-center mt-[50px]">
          <div className="mr-[50px]">
            <h2 className="text-[40px] text-white font-bold text-left">
              Donec dictum tristique porta. Etiam convallis lorem lobortis nulla
              molestie, nec
            </h2>
            <p className="text-[24px] text-[#B8B8B8] text-left mt-[25px]">
              Maecenas dignissim justo eget nulla rutrum molestie. Maecenas
              lobortis sem dui, vel rutrum risus tincidunt ullamcorper
            </p>
            <button className="bg-[#DF5B04] py-[15px] px-[28px] text-white font-semibold flex items-end mt-[60px]">
              Browse Classes{" "}
              <img src={arrowLeft} alt="arrow" className="ml-[10px]" />
            </button>
          </div>
          <img src={imagecont2} alt="" />
        </div>
      </div> */}
    </div>
  );
}

export default ImageCont;
