import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Banner from "../components/Category/Banner";
import ListPaginate from "../components/ListPaginate";
import Sidebar from "../components/Category/Sidebar";
import CategoryCard from "../components/Category/CategoryCard";
import apiHelper from "../api/apiHelper";

const samplecategories = [
  "All Categories",
  "Animation",
  "Design",
  "Illustration",
  "Lifestyle",
  "Photo & Film",
  "Business",
  "Writing",
];

function VideoCast() {
  const [selected, setSelected] = useState(0);
  const [humansVideos, setHumansVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const params = useParams();

  useEffect(() => {
    const getvideos = async () => {
      await apiHelper
        .axiosCallGet(
          `${process.env.REACT_APP_BASE_URL}/api/?action=listVideos`
        )
        .then((response) => {
          console.log(response);
          setHumansVideos(response);
          setCategories(samplecategories);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getvideos();
  }, []);

  return (
    <>
      <Banner video={true} />

      <div className="w-full justify-center flex lg:hidden">
        <div className="w-[87%] mt-[50px]">
          <Sidebar data={categories} value={selected} setValue={setSelected} />
          <ListPaginate renderCard="video" list={humansVideos} />
        </div>
      </div>

      <div className="w-full justify-center hidden lg:flex">
        <div className="w-[87%] flex">
          <div className="w-1/4">
            <Sidebar
              data={categories}
              value={selected}
              setValue={setSelected}
            />
          </div>
          <div className="w-3/4">
            <ListPaginate renderCard="video" list={humansVideos} />
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoCast;
