/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import Logo from "./Logo";
import hamIcon from "../assets/ham.png";
import search from "../assets/Search.png";
import dropdown from "../assets/dropdown.png";
import usericon from "../assets/svg/User_icon2.svg";
import { useAuth0 } from "@auth0/auth0-react";

function Header() {
  const { loginWithRedirect, user, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState("bg-opacity-50");

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: `https://${process.env.REACT_APP_DOMAIN_NAME}/api/v2/`,
          scope: "read:current_user",
          // ignoreCache: true,
        });
        localStorage.setItem("token", token);
      } catch (e) {
        console.log(e.message);
      }
    };
    getAccessToken();
    const interval = setInterval(
      () => isAuthenticated && getAccessToken(),
      10000
    );
    return () => {
      clearInterval(interval);
    };
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (isOpen) {
      setOpacity("bg-opacity-100");
    } else {
      checkScroll();
    }
  }, [isOpen]);

  const checkScroll = () => {
    if (window.scrollY > 50) {
      setOpacity("bg-opacity-100 transition duration-500");
    } else {
      setOpacity("bg-opacity-50 transition duration-500");
    }
  };

  window.addEventListener("scroll", checkScroll);

  return (
    <nav
      className={`bg-transparent bg-[#121212] bg-cover bg-no-repeat fixed w-full z-20  h-[86px] ${opacity}`}
    >
      <div className="w-[87%] mx-auto h-full">
        <div className="h-full flex items-center justify-between">
          <div className="flex lg:hidden">
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              type="button"
              className="w-[54px] h-[54px] bg-[#222222] inline-flex items-center justify-center p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <img src={hamIcon} alt="" />
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>

          <div className="flex-shrink-0 ml-[-50px] lg:ml-0">
            <Logo />
          </div>

          <div className="">
            <div className="hidden lg:block">
              <div className="ml-10 flex items-center">
                <Link
                  to="/"
                  className="tracking-[1px] text-white px-[12px] py-2 rounded-md text-sm font-black text-[16px] hover:text-[#DF5B04] font-avenir-black flex justify-center items-center"
                >
                  {" "}
                  Home
                </Link>

                {/* <a
                  href="#"
                  className="tracking-[1px] text-white px-[12px] py-2 rounded-md text-sm font-black text-[16px] hover:text-[#DF5B04] font-avenir-black flex justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 34.326 34.326"
                    class="s-ion-icon"
                    fill="#fff"
                  >
                    <path
                      class="a"
                      d="M112.507,39.674l-7.721-7.72a14.993,14.993,0,1,0-2.121,2.121l7.721,7.72ZM84.694,30.945a11.993,11.993,0,1,1,8.482,3.514A11.916,11.916,0,0,1,84.694,30.945Z"
                      transform="translate(-78.181 -7.469)"
                    ></path>
                    <path
                      class="a"
                      d="M87.295,22.464h-3a8.892,8.892,0,0,0,8.881,8.881v-3A5.887,5.887,0,0,1,87.295,22.464Z"
                      transform="translate(-78.181 -7.469)"
                    ></path>
                  </svg>
                </a> */}
                {!isAuthenticated ? (
                  <button
                    onClick={loginWithRedirect}
                    className="tracking-[1px] text-white px-[12px] py-2 rounded-md text-sm font-black text-[16px] hover:text-[#DF5B04] font-avenir-black flex justify-center items-center"
                  >
                    Login / Signup{" "}
                    {/* <img src={usericon} alt="" className="h-full" /> */}
                  </button>
                ) : (
                  <Link
                    to="/myaccount"
                    className="tracking-[1px] text-white px-[12px] py-2 rounded-md text-sm font-black text-[16px] hover:text-[#DF5B04] font-avenir-black flex justify-center items-center"
                  >
                    My Account{" "}
                    {/* <img src={usericon} alt="" className="h-full" /> */}
                  </Link>
                )}
                {/* <a
                  href="#"
                  className="tracking-[1px] text-white px-[12px] py-2 rounded-md text-sm font-black text-[16px] hover:text-[#DF5B04] font-avenir-black flex justify-center items-center"
                >
                  E / A
                </a> */}
                {/* <a
                  href="#"
                  className="text-white px-3 py-2 rounded-md text-sm font-bold text-[16px] hover:text-[#DF5B04] font-avenir-black flex justify-center items-center"
                >
                  All categories{" "}
                  <img src={dropdown} alt="" className="ml-[10px]" />
                </a>
                <a
                  href="#"
                  className="text-gray-300 px-3 py-2 rounded-md text-sm font-bold text-[16px] hover:text-[#DF5B04] font-avenir-black"
                >
                  View plans
                </a>

                <a
                  href="#"
                  className="text-gray-300 px-3 py-2 rounded-md text-sm font-bold text-[16px] hover:text-[#DF5B04] font-avenir-black flex justify-center items-center"
                >
                  Search <img src={search} alt="" className="ml-[10px]" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className={`bg-[#121212] ${opacity}`} id="mobile-menu">
            <div
              ref={ref}
              className="px-2 pt-2 pb-3 space-y-1 sm:px-3 text-center"
            >
              <Link
                to="/"
                className="text-white block px-3 py-2 rounded-md text-base font-medium  font-avenir-black hover:text-[#DF5B04] text-center"
              >
                {" "}
                Home
              </Link>

              {!isAuthenticated ? (
                <button
                  onClick={loginWithRedirect}
                  className="text-white px-3 py-2 rounded-md text-base font-medium  font-avenir-black hover:text-[#DF5B04]"
                >
                  Login / Signup{" "}
                  {/* <img src={usericon} alt="" className="h-full" /> */}
                </button>
              ) : (
                <Link
                  to="/myaccount"
                  className="text-white block px-3 py-2 rounded-md text-base font-medium  font-avenir-black hover:text-[#DF5B04]"
                >
                  My Account{" "}
                  {/* <img src={usericon} alt="" className="h-full" /> */}
                </Link>
              )}
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
}

export default Header;
