import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import arrowDown from "../../assets/Arrow_drop_down.png";

import project1 from "../../assets/project1.png";
import project2 from "../../assets/project2.png";
import video from "../../assets/svg/play.svg";
import mic from "../../assets/svg/mic.svg";
import project3 from "../../assets/project3.png";
import VideoThumbnailCard from "../VideoThumbnailCard";
import EventBox from "../Events/EventBox";

const data = {
  resources: [
    {
      duration: "1h 10m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "112",
      img: project1,
      by: "Esther Nariyoshi",
      link: "#",
    },
    {
      duration: "3h 12m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "212",
      link: "#",
      img: project2,
      by: "Harry Jones",
    },
    {
      duration: "5h 10m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "234",
      link: "#",
      img: project3,
      by: "Lucas Ridley",
    },
    {
      duration: "1h 10m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "112",
      img: project1,
      by: "Esther Nariyoshi",
      link: "#",
    },
    {
      duration: "3h 12m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "212",
      link: "#",
      img: project2,
      by: "Harry Jones",
    },
    {
      duration: "5h 10m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "234",
      link: "#",
      img: project3,
      by: "Lucas Ridley",
    },
    {
      duration: "1h 10m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "112",
      img: project1,
      by: "Esther Nariyoshi",
      link: "#",
    },
    {
      duration: "3h 12m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "212",
      link: "#",
      img: project2,
      by: "Harry Jones",
    },
    {
      duration: "5h 10m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "234",
      link: "#",
      img: project3,
      by: "Lucas Ridley",
    },
    {
      duration: "1h 10m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "112",
      img: project1,
      by: "Esther Nariyoshi",
      link: "#",
    },
    {
      duration: "3h 12m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "212",
      link: "#",
      img: project2,
      by: "Harry Jones",
    },
    {
      duration: "5h 10m",
      description:
        "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
      stdcount: "234",
      link: "#",
      img: project3,
      by: "Lucas Ridley",
    },
  ],
};

function Projects({
  updateModal,
  humanVideos,
  humanDetails,
  setSelectedVideo,
}) {
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(2);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${0} to ${endOffset}`);
    setCurrentItems(data.resources.slice(0, endOffset));
    setPageCount(Math.ceil(data.resources.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    // const newOffset = (count * itemsPerPage) % data.resources.length;
    // console.log(
    //   `User requested page number ${count}, which is offset ${newOffset}`
    // );
    // setItemOffset(newOffset);
    // setCount((prevState) => prevState++);
    setCurrentItems((pervState) => pervState.concat(data.resources));
  };

  return (
    <>
      {humanVideos.length > 0 && (
        <div className="mt-[70px] flex justify-center font-opensans tracking-wider">
          <div className="w-[87%]">
            <h2 className="text-white text-[24px] md:text-[27px] font-bold font-sans">
              {humanDetails?.name} Podcasts{" "}
              <span className="text-[#DF5B04]">{humanVideos.length}</span>
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-[20px] md:gap-[24px] mt-[40px]">
              {humanVideos.map((video) => {
                return (
                  <VideoThumbnailCard
                    data={video}
                    updateModal={updateModal}
                    key={video.id}
                    setSelectedVideo={setSelectedVideo}
                  />
                );
              })}
            </div>

            <div className="flex justify-center w-full">
              <button
                className="bg-[#DF5B04] text-white font-semibold flex items-center px-[2rem] min-h-[4rem] mt-[60px]"
                onClick={handlePageClick}
              >
                Load More{" "}
                <img src={arrowDown} alt="arrow" className="ml-[10px]" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Projects;
