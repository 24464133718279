import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import card from "../../assets/card.png";
import unchecked from "../../assets/svg/timeslotunchecked.svg";
import checked from "../../assets/svg/timeslotchecked.svg";
import durationImg from "../../assets/svg/duration.svg";
import timeslotmarker from "../../assets/svg/timeslotmarker.svg";
import apiHelper from "../../api/apiHelper";
import moment from "moment/moment";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const CheckoutModal = ({
  showModal,
  updateModal,
  humanDetails,
  event,
  disabledDates,
}) => {
  const { isAuthenticated } = useAuth0();
  const [timeSlot, setTimeSlot] = useState();
  const [loading, setLoading] = useState(false);
  const [timeStamps, setTimeStamps] = useState([]);
  const [alert, setAlert] = useState("");
  const [value, onChange] = useState(new Date());
  const [duration, setDuration] = useState(1);
  const [userDetailModal, setUserDetailModal] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const naviagte = useNavigate();

  useEffect(() => {
    onChange(new Date(disabledDates.start));
  }, [disabledDates]);

  useEffect(() => {
    const getTimeSlots = async () => {
      const formData = new FormData();
      formData.append("action", "availiableSlots");
      formData.append("human_id", humanDetails?.id);
      formData.append("date", moment(value).format("YYYY-MM-DD"));
      await apiHelper
        .axiosCallPost(formData, isAuthenticated)
        .then((response) => {
          console.log(response);
          setTimeStamps(
            response.time_slots.sort(
              (a, b) => b.available_limit - a.available_limit
            )
          );
          setTimeSlot(
            response.time_slots.find((slot, i) => slot.available_limit)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getTimeSlots();
  }, [value, humanDetails?.id]);

  const openUserDetailModal = () => {
    if (!timeSlot) {
      showAlert("Please select a timeSlot");
      return;
    }
    setUserDetailModal(true);
  };

  const createOrder = async () => {
    if (!timeSlot) {
      showAlert("Please select a timeSlot");
      return;
    }
    if (event.allow_guest_booking === "1" && !isAuthenticated) {
      if (!userDetails.name) {
        showAlert("Please enter Name");
        return;
      }
      if (!userDetails.email) {
        showAlert("Please enter Email");
        return;
      }
      if (!userDetails.mobile) {
        showAlert("Please enter Mobile Number");
        return;
      }
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "createOrder");
    formData.append("human_id", humanDetails.id);
    formData.append("event_id", event.id);
    formData.append("redirect_url", window.location.origin + "/myaccount");
    formData.append("slot_id", timeSlot.time_slot_id);
    formData.append(
      "start_date_timing",
      moment(value).format("YYYY-MM-DD") + " " + timeSlot.start_time
    );
    formData.append(
      "end_date_timing",
      moment(value).format("YYYY-MM-DD") + " " + timeSlot.end_time
    );
    if (event.allow_guest_booking === "1" && !isAuthenticated) {
      formData.append("name", userDetails.name);
      formData.append("email", userDetails.email);
      formData.append("mobile", userDetails.mobile);
    }
    await apiHelper
      .axiosCallPost(formData, isAuthenticated)
      .then((response) => {
        console.log(response);
        if (response.success) {
          if (event?.skip_payment !== "1") {
            naviagte(`/order-confirmation/${response.order_number}`);
            // initiatePayment(response.order_number);
          } else {
            setLoading(false);
          }
        } else {
          showAlert(response.msg);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const initiatePayment = async (orderNumber) => {
    const formData = new FormData();
    formData.append("action", "initiatePayment");
    formData.append("order_number", orderNumber);
    await apiHelper
      .axiosCallPost(formData, isAuthenticated)
      .then((response) => {
        console.log(response);
        if (response.success) {
          window.location.href = response.payment_url;
        } else {
          showAlert(response.msg);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const showAlert = (msg) => {
    setAlert(msg);
    setTimeout(() => {
      setAlert("");
    }, 3000);
  };

  const alertPopup = () => (
    <>
      {alert && (
        <div className="flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none font-opensans tracking-wider h-full md:h-auto">
          <div className="w-auto my-6 mx-auto max-w-full md:max-w-3xl h-full md:h-auto mr-6">
            <div
              className="flex justify-end p-4 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800"
              role="alert"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 inline w-5 h-5 mr-3"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Info</span>
              <div>
                <span className="font-medium">{alert}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  window.onclick = function (event) {
    var modal = document.getElementById("modal-box");
    if (event.target === modal) updateModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <div
            id="modal-box"
            className="backdrop-blur-sm flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50 font-opensans tracking-wider h-full md:h-auto"
          >
            <div className="relative w-auto my-6 mx-auto max-w-full md:max-w-3xl h-full md:h-auto">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#222222] outline-none focus:outline-none p-[30px] md:p-[50px]">
                <button
                  className="bg-transparent border-0 text-black float-right absolute top-[15px] right-[15px]"
                  onClick={() => updateModal(false)}
                >
                  <span className="absolute right-[15px] h-[24px] w-[2px] bg-[#B8B8B8] rotate-45"></span>
                  <span className="absolute right-[15px] h-[24px] w-[2px] bg-[#B8B8B8] rotate-[-45deg]"></span>
                </button>
                <div className="flex justify-between flex-col md:flex-row">
                  {moment(disabledDates.start).format("YYYY-MM-DD") !==
                    moment(disabledDates.end).format("YYYY-MM-DD") && (
                    <Calendar
                      maxDate={disabledDates.end}
                      minDate={disabledDates.start}
                      // tileDisabled={({ activeStartDate, date, view }) =>
                      //   date.getDate() === 12
                      // }
                      onChange={onChange}
                      value={value}
                      className="text-[#B8B8B8] !bg-transparent !border-0 !font-opensans"
                    />
                  )}
                  <div className="md:ml-[30px] md:mt-0 mt-[30px] flex flex-col justify-between">
                    {/* <div className="relative flex items-center mb-[44px] mt-[40px]">
                      <div>
                        <img src={durationImg} alt="" />
                        <p className="text-[14px] text-white absolute">1h</p>
                      </div>

                      <input
                        id="steps-range"
                        type="range"
                        min="1"
                        max="8"
                        value={duration}
                        onChange={(e) => {
                          setDuration(e.target.value);
                        }}
                        step="1"
                        class="w-[88%] mx-[20px] z-[10000] h-2 bg-gray-200 absolute opacity-0 cursor-pointer"
                      ></input>

                      <div className="w-[88%] flex mx-[8px] justify-between text-xs top-[4.5px] cursor-pointer hover:cursor-pointer z-50">
                        <span
                          className={`z-50 h-[2px] w-[2px] bg-[#B8B8B8] after:w-[43px] after:h-[2px] after:block after:bg-opacity-50 ${
                            duration >= 2 ? "completed" : "incomplete"
                          }`}
                        ></span>
                        <span
                          className={`z-50 h-[2px] w-[2px] bg-[#B8B8B8] after:w-[43px] after:h-[2px] after:block after:bg-opacity-50 ${
                            duration >= 3 ? "completed" : "incomplete"
                          }`}
                        ></span>
                        <span
                          className={`z-50 h-[2px] w-[2px] bg-[#B8B8B8] after:w-[43px] after:h-[2px] after:block after:bg-opacity-50 ${
                            duration >= 4 ? "completed" : "incomplete"
                          }`}
                        ></span>
                        <span
                          className={`z-50 h-[2px] w-[2px] bg-[#B8B8B8] after:w-[43px] after:h-[2px] after:block after:bg-opacity-50 ${
                            duration >= 5 ? "completed" : "incomplete"
                          }`}
                        ></span>
                        <span
                          className={`z-50 h-[2px] w-[2px] bg-[#B8B8B8] after:w-[43px] after:h-[2px] after:block after:bg-opacity-50 ${
                            duration >= 6 ? "completed" : "incomplete"
                          }`}
                        ></span>
                        <span
                          className={`z-50 h-[2px] w-[2px] bg-[#B8B8B8] after:w-[43px] after:h-[2px] after:block after:bg-opacity-50 ${
                            duration >= 7 ? "completed" : "incomplete"
                          }`}
                        ></span>
                        <span
                          className={`z-50 h-[2px] w-[2px] bg-[#B8B8B8] after:w-[43px] after:h-[2px] after:block after:bg-opacity-50 ${
                            duration >= 8 ? "completed" : "incomplete"
                          }`}
                        ></span>
                        <span className="h-[2px] w-[2px] bg-[#B8B8B8]"></span>
                      </div>

                      <div>
                        <img src={durationImg} alt="" />
                        <p className="text-[14px] text-white absolute">8h</p>
                      </div>

                      <div className="absolute top-[-40px] w-[88%] mx-[10px]">
                        <div
                          className="timeslotmarker w-[25px] h-[32px] text-[14px] font-medium text-white flex justify-center items-start"
                          style={{ marginLeft: 43 * (duration - 1) + "px" }}
                        >
                          {duration}h
                        </div>
                      </div>
                    </div> */}
                    <div className="relative flex items-center mb-[44px] mt-[20px] text-white">
                      {
                        timeStamps.filter((stamp) => stamp.available_limit)
                          .length
                      }{" "}
                      available timeslots for{" "}
                      {moment(value).format("DD-MM-YYYY")}
                    </div>

                    <ul class="flex flex-col w-full max-w-[346px] md:w-[346px] gap-[10px] mb-[30px]">
                      {timeStamps.map((ts, index) => (
                        <li>
                          <input
                            type="radio"
                            id={index}
                            name="timeslot"
                            disabled={!ts.available_limit}
                            value={index}
                            defaultChecked={timeSlot === ts}
                            className="hidden peer"
                            onClick={() => setTimeSlot(ts)}
                          />
                          <label
                            for={index}
                            className={`inline-flex justify-between items-center px-[20px] py-[12px] w-full text-white bg-[#121212] ${
                              !ts.available_limit
                                ? "cursor-not-allowed opacity-50"
                                : "cursor-pointer"
                            } peer-checked:bg-[#DF5B04]`}
                          >
                            <div className="block">
                              <div className="w-full text-[14px] font-normal ">
                                {ts.start_time} - {ts.end_time}
                              </div>
                            </div>
                            <img
                              src={timeSlot === ts ? checked : unchecked}
                              alt=""
                            />
                          </label>
                        </li>
                      ))}
                    </ul>
                    <div className="flex flex-col">
                      <div className="flex w-full justify-between items-center">
                        <h3 className="text-[18px] text-white font-semibold">
                          Total Price
                        </h3>
                        <h3 className="text-[18px] text-white font-semibold">
                          AED 0
                        </h3>
                      </div>
                      <button
                        disabled={loading}
                        className={`${
                          loading ? "bg-[#808080]" : "bg-[#DF5B04]"
                        } py-[15px] px-[28px] text-white font-bold flex justify-center items-center mt-[20px] w-full`}
                        onClick={() =>
                          event.allow_guest_booking === "1" && !isAuthenticated
                            ? openUserDetailModal()
                            : createOrder()
                        }
                      >
                        {loading && (
                          <div role="status">
                            <svg
                              class="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300 mr-5"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        )}
                        {event.allow_guest_booking === "1" && !isAuthenticated
                          ? "Reserve Slot"
                          : "Checkout"}
                        <img src={card} alt="arrow" className="ml-[10px]" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {alertPopup()}
              {userDetailModal && (
                <div
                  id="modal-box"
                  className="backdrop-blur-sm flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50 font-opensans tracking-wider h-full md:h-auto"
                >
                  <div className="relative w-auto my-6 mx-auto max-w-full md:max-w-3xl md:h-auto">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#222222] outline-none focus:outline-none p-[30px] md:p-[50px]">
                      <button
                        className="bg-transparent border-0 text-black float-right absolute top-[15px] right-[15px]"
                        onClick={() => setUserDetailModal(false)}
                      >
                        <span className="absolute right-[15px] h-[24px] w-[2px] bg-[#B8B8B8] rotate-45"></span>
                        <span className="absolute right-[15px] h-[24px] w-[2px] bg-[#B8B8B8] rotate-[-45deg]"></span>
                      </button>
                      <div class="mb-6">
                        <label
                          for="text"
                          class="block mb-2 text-sm font-medium text-white dark:text-gray-300"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          id="text"
                          name="name"
                          placeholder="Name"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px] p-2.5 dark:bg-[#202020] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                          required=""
                          onChange={(e) =>
                            setUserDetails((prev) => {
                              return {
                                ...prev,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                        />
                      </div>
                      <div class="mb-6">
                        <label
                          for="email"
                          class="block mb-2 text-sm font-medium text-white dark:text-gray-300"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          placeholder="Email"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px] p-2.5 dark:bg-[#202020] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                          required=""
                          onChange={(e) =>
                            setUserDetails((prev) => {
                              return {
                                ...prev,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                        />
                      </div>
                      <div class="mb-6">
                        <label
                          for="number"
                          class="block mb-2 text-sm font-medium text-white dark:text-gray-300"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          id="number"
                          name="mobile"
                          placeholder="Mobile Number"
                          class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[300px] p-2.5 dark:bg-[#202020] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                          required=""
                          onChange={(e) =>
                            setUserDetails((prev) => {
                              return {
                                ...prev,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                        />
                      </div>
                      <button
                        disabled={loading}
                        className={`${
                          loading ? "bg-[#808080]" : "bg-[#DF5B04]"
                        } py-[15px] px-[28px] text-white font-bold flex justify-center items-center mt-[20px] w-full`}
                        onClick={createOrder}
                      >
                        {loading && (
                          <div role="status">
                            <svg
                              class="inline mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300 mr-5"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </div>
                        )}
                        Reserve Slot{" "}
                      </button>
                    </div>
                  </div>
                  {alertPopup()}
                </div>
              )}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default CheckoutModal;
