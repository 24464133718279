const axios = require("axios");

class ApiHelper {
  async axiosCallGet(url) {
    var config = {
      method: "GET",
      url: url,
      //   headers: {
      //   },
    };
    let val = await axios(config)
      .then((res) => {
        return res.data.data;
      })
      .catch((e) => {
        throw e;
      });
    return val;
  }

  async axiosCallPost(data, isAuthenticated) {
    var headers = {};
    if (isAuthenticated) {
      headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
    }
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL}/api/`,
      data: data,
      headers: headers,
    };
    let val = await axios(config)
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        throw e;
      });
    return val;
  }
}

export default new ApiHelper();
