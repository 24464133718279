import React from "react";

function AccountSetting() {
  return (
    <section class="ml-[15px] p-6 mx-auto bg-white shadow-md dark:bg-[#202020]">
      <h2 class="text-lg font-semibold text-gray-700 capitalize dark:text-white">
        Account settings
      </h2>

      <form>
        <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <div>
            <label class="text-gray-700 dark:text-gray-200" for="username">
              First Name
            </label>
            <input
              id="username"
              type="text"
              class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 dark:bg-[#202020] dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            />
          </div>
          <div>
            <label class="text-gray-700 dark:text-gray-200" for="username">
              Last Name
            </label>
            <input
              id="username"
              type="text"
              class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 dark:bg-[#202020] dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            />
          </div>

          <div>
            <label class="text-gray-700 dark:text-gray-200" for="emailAddress">
              Email Address
            </label>
            <input
              id="emailAddress"
              type="email"
              class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 dark:bg-[#202020] dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            />
          </div>

          <div>
            <label class="text-gray-700 dark:text-gray-200" for="username">
              Phone Number
            </label>
            <input
              id="username"
              type="text"
              class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 dark:bg-[#202020] dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            />
          </div>

          <div>
            <label class="text-gray-700 dark:text-gray-200" for="password">
              Gender
            </label>
            <select
              id="password"
              type="text"
              class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 dark:bg-[#202020] dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
        </div>

        <div class="flex justify-end mt-6">
          <button class="px-8 py-2.5 mr-[20px] leading-5 text-white transition-colors duration-300 transform bg-[#121212] focus:outline-none focus:bg-gray-600">
            Change password
          </button>
          <button class="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-[#DF5B04] focus:outline-none focus:bg-gray-600">
            Save
          </button>
        </div>
      </form>
    </section>
  );
}

export default AccountSetting;
