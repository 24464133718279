import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import About from "../components/Product/About";
import Banner from "../components/Product/Banner";
import CheckoutModal from "../components/Product/CheckoutModal";
import Projects from "../components/Product/Projects";
import VideoModal from "../components/VideoModal";
import apiHelper from "../api/apiHelper";
import moment from "moment/moment";

function HumanDetails() {
  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [humanDetails, setHumanDetails] = useState();
  const [event, setEvent] = useState();
  const [humanVideos, setHumanVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState();
  const [disabledDates, setDisabledDates] = useState({
    start: new Date(),
    end: new Date(),
  });
  const params = useParams();

  useEffect(() => {
    const getEvent = async () => {
      setHumanDetails();
      await apiHelper
        .axiosCallGet(
          `${process.env.REACT_APP_BASE_URL}/api/?action=listEvents&slug=${params.event_slug}`
        )
        .then((response) => {
          console.log(response);
          setHumanDetails(
            response[0].humans.find((human) => human.slug === params.human_slug)
          );
          setEvent(response[0]);
          setDisabledDates({
            start:
              moment(
                moment(response[0].end_date_time).format("YYYY-MM-DD")
              ).isBefore(moment(new Date()).format("YYYY-MM-DD"), "day") ||
              moment(
                moment(response[0].start_date_time).format("YYYY-MM-DD")
              ).isAfter(moment(new Date()).format("YYYY-MM-DD"), "day")
                ? new Date(response[0].start_date_time)
                : new Date(),
            end: new Date(response[0].end_date_time),
          });
          getHumanVideos();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getHumanVideos = async () => {
      await apiHelper
        .axiosCallGet(
          `${process.env.REACT_APP_BASE_URL}/api/?action=listVideos&slug=${params.human_slug}`
        )
        .then((response) => {
          console.log(response);
          setHumanVideos(response);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getEvent();
  }, [params.human_slug]);

  const updateModal = (data) => {
    setShowModal(data);
  };
  const updateVideoModal = (data) => {
    setShowVideoModal(data);
  };

  return (
    <>
      <div className="hidden justify-center mb-[25px] md:flex font-opensans tracking-wider">
        <div className="flex items-center pt-[104px] w-[87%]">
          <Link to="/">
            <p className="text-[14px] font-bold text-[#A74403] mr-[15px] font-opensans tracking-wider">
              Home
            </p>
          </Link>
          <span className="w-[3px] h-[3px] bg-[#B8B8B8] opacity-50"></span>
          <Link to={`/events/${event?.slug}`}>
            <p className="text-[14px] font-bold text-[#B8B8B8] mx-[15px] font-opensans tracking-wider">
              {event?.name}
            </p>
          </Link>
          <span className="w-[3px] h-[3px] bg-[#B8B8B8] opacity-50"></span>
          {humanDetails && (
            <p className="text-[14px] font-bold text-white ml-[15px] font-opensans tracking-wider">
              {humanDetails.name}
            </p>
          )}
        </div>
      </div>
      <About
        updateModal={updateModal}
        humanDetails={humanDetails}
        disabledDates={disabledDates}
        event={event}
      />
      <Projects
        updateModal={updateVideoModal}
        humanVideos={humanVideos}
        humanDetails={humanDetails}
        setSelectedVideo={setSelectedVideo}
      />
      <CheckoutModal
        showModal={showModal}
        updateModal={updateModal}
        humanDetails={humanDetails}
        event={event}
        disabledDates={disabledDates}
      />
      <VideoModal
        showModal={showVideoModal}
        updateModal={updateVideoModal}
        selectedVideo={selectedVideo}
      />
    </>
  );
}

export default HumanDetails;
