import React, { useState, useEffect } from "react";
import Banner from "../components/Home/Banner";
import Carousel from "../components/Home/Carousel";
import ImageCont from "../components/Home/ImageCont";
import TeacherCarousel from "../components/Home/TeacherCarousel";
import Instructor from "../components/Home/Instructor";
import VideoModal from "../components/VideoModal";
import apiHelper from "../api/apiHelper";

function Home() {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [humansVideos, setHumansVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState();
  const [humansList, setHumansList] = useState([]);

  useEffect(() => {
    const getEvent = async () => {
      await apiHelper
        .axiosCallGet(
          `${process.env.REACT_APP_BASE_URL}/api/?action=listEvents&slug=${process.env.REACT_APP_HIGHLIGHT_EVENT}`
        )
        .then((response) => {
          console.log(response);
          setHumansList(response[0].humans);
          getvideos();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getvideos = async () => {
      await apiHelper
        .axiosCallGet(
          `${process.env.REACT_APP_BASE_URL}/api/?action=listVideos`
        )
        .then((response) => {
          console.log(response);
          setHumansVideos(response);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getEvent();
  }, []);

  const updateVideoModal = (data) => {
    setShowVideoModal(data);
  };

  return (
    <>
      <Banner />
      <TeacherCarousel humansList={humansList} />
      <ImageCont />
      <Carousel
        updateModal={updateVideoModal}
        humansVideos={humansVideos}
        setSelectedVideo={setSelectedVideo}
      />
      <Instructor />
      <VideoModal
        updateModal={updateVideoModal}
        showModal={showVideoModal}
        selectedVideo={selectedVideo}
      />
    </>
  );
}

export default Home;
