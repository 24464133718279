import React from "react";
import { Link } from "react-router-dom";
import categorybanner from "../../assets/categorybanner.png";
import arrowLeft from "../../assets/Arrow_drop_right.png";

function Banner({ event, isCategory, video, scrollToHumans }) {
  return (
    <div className="z-0 md:pt-0 mb-10">
      <section class="w-full h-[456px] md:h-[570px]">
        <img
          src={
            event?.banner_img
              ? `${process.env.REACT_APP_BASE_URL}/${event.banner_img}`
              : categorybanner
          }
          className="object-cover w-full h-[456px] md:h-[570px]"
          alt="Home bg"
        />
      </section>

      <section class="w-full h-[456px] md:h-[570px] absolute top-0 left-0 flex justify-center items-center  bg-[#121212] opacity-70"></section>
      <section class="w-full h-[356px] md:h-[510px] absolute top-[86px] md:top-0 left-0 flex justify-center items-center flex-col">
        <div className="w-[87%]">
          <div className="w-full md:w-3/5 mt-20">
            <h2 className="font-avenir-black text-white text-[24px] text-center md:text-start md:text-[40px] font-bold mb:[10px] md:mb-[30px]">
              {event
                ? event.name
                : video
                ? "The Latest Speaking Books Videos"
                : "Previous Editions"}
            </h2>
            <h3 className="text-[#B8B8B8] text-[20px] text-center hidden sm:block md:hidden xl:block 2xl:block md:text-start md:text-[24px] font-medium">
              {event
                ? event.description
                : video
                ? "Browse through the latest videos by our speaking books based on topics, themes or categories."
                : "Browse through our collection of speaking books and their conversations by editions."}
            </h3>
            <h3 className="text-[#B8B8B8] text-[20px] text-center block sm:hidden md:block xl:hidden 2xl:hidden  md:text-start md:text-[24px] font-medium">
              {event
                ? event.small_description
                : video
                ? "Browse through the latest videos by our speaking books based on topics, themes or categories."
                : "Browse through our collection of speaking books and their conversations by editions."}
            </h3>
          </div>
          <div className="flex mt-[30px] md:mt-[60px] justify-center md:justify-between items-end w-full">
            <button
              className="bg-[#DF5B04] hover:bg-[#222222] transition-colors text-white font-semibold flex items-center px-[2rem] min-h-[4rem]"
              onClick={scrollToHumans}
            >
              {video ? "Browse" : "Browse"}
              <img src={arrowLeft} alt="arrow" className="ml-[10px]" />
            </button>
            <div className="items-center hidden md:flex">
              <Link to="/">
                <p className="text-[14px] font-bold text-[#A74403] mr-[15px]">
                  Home
                </p>
              </Link>
              <span className="w-[3px] h-[3px] bg-[#B8B8B8] opacity-50"></span>

              <Link to={video ? "/videocast" : "/events"}>
                <p
                  className={`text-[14px] font-bold ${
                    isCategory ? "text-[#A74403]" : "text-white"
                  } mr-[15px] ml-[15px]`}
                >
                  {video ? "VideoCast" : "Event"}
                </p>
              </Link>

              {isCategory && (
                <>
                  <span className="w-[3px] h-[3px] bg-[#B8B8B8] opacity-50"></span>
                  <p className="text-[14px] font-bold text-white ml-[15px]">
                    {event ? event.slug : "Category"}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Banner;
