import React from "react";
import video from "../assets/svg/play.svg";
import location from "../assets/svg/location.svg";
import ticket from "../assets/svg/ticket.svg";

function VideoThumbnailCard({ updateModal, data, setSelectedVideo }) {
  return (
    <div
      className="carousel-item relative w-full h-[320px] snap-start flex flex-col cursor-pointer justify-evenly bg-[#222222]"
      onClick={() => {
        if (updateModal) {
          setSelectedVideo(data);
          updateModal(true);
        }
      }}
    >
      <div
        className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0"
        // style={{
        //   backgroundImage: `url(${data.img || ""})`,
        // }}
      >
        <div className="h-1/2 w-full relative">
          <img
            src={`${process.env.REACT_APP_BASE_URL}/${data.thumbnail}`}
            alt={data.title}
            className="object-cover w-full h-full"
          />
        </div>

        <div className="absolute w-full top-0 h-1/2 flex justify-center items-center">
          <div className="w-[50px] h-[50px] p-[13px] bg-[#222222] bg-opacity-50 hover:bg-opacity-100 transition-all duration-300 hover:border-[#222222] border-[#DF5B04] border-[2px] cursor-pointer">
            <img
              src={video}
              alt={data.title}
              className="aspect-square w-full h-full"
            />
          </div>
        </div>

        {/* <div className="top-0 absolute left-0 w-full h-full bg-gradient-to-t from-[#1B1C20] to-[#222222] opacity-30"></div> */}
        {/* <div className="top-0 absolute left-0 w-full h-full bg-gradient-to-t from-[#1B1C20]"></div> */}

        <div className="bottom-0 absolute text-white h-1/2 text-[18px] font-bold text-start p-[25px]">
          <div className="flex flex-col justify-between h-full">
            <div>
              <h2 className="text-[16px] text-white font-bold mt-[15px] mb-[10px] w-full md:w-full text-ellipsis overflow-hidden projecttitle">
                {data.title}
              </h2>
            </div>

            <h4 className="text-[#B8B8B8] font-medium text-[16px]">
              {data.name}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoThumbnailCard;
