import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import project1 from "../../assets/project1.png";
import project2 from "../../assets/project2.png";
import project3 from "../../assets/project3.png";
import leftslide from "../../assets/leftslide.png";
import arrowLeft from "../../assets/Arrow_drop_right.png";
import rightslide from "../../assets/rightslide.png";
import filter from "../../assets/filter.png";
import { Slider } from "../Carousel/lib";
import VideoThumbnailCard from "../VideoThumbnailCard";
// Data

const samplecategories = [
  "All Categories",
  "Animation",
  "Design",
  "Illustration",
  "Lifestyle",
  "Photo & Film",
  "Business",
  "Writing",
];

const sampledata = [
  {
    duration: "1h 10m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "112",
    img: project1,
    by: "Esther Nariyoshi",
    link: "#",
  },
  {
    duration: "3h 12m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "212",
    link: "#",
    img: project2,
    by: "Harry Jones",
  },
  {
    duration: "5h 10m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "234",
    link: "#",
    img: project3,
    by: "Lucas Ridley",
  },
  {
    duration: "1h 10m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "112",
    img: project1,
    by: "Esther Nariyoshi",
    link: "#",
  },
  {
    duration: "3h 12m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "212",
    link: "#",
    img: project2,
    by: "Harry Jones",
  },
  {
    duration: "5h 10m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "234",
    link: "#",
    img: project3,
    by: "Lucas Ridley",
  },
  {
    duration: "1h 10m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "112",
    img: project1,
    by: "Esther Nariyoshi",
    link: "#",
  },
  {
    duration: "3h 12m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "212",
    link: "#",
    img: project2,
    by: "Harry Jones",
  },
  {
    duration: "5h 10m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "234",
    link: "#",
    img: project3,
    by: "Lucas Ridley",
  },
  {
    duration: "1h 10m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "112",
    img: project1,
    by: "Esther Nariyoshi",
    link: "#",
  },
  {
    duration: "3h 12m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "212",
    link: "#",
    img: project2,
    by: "Harry Jones",
  },
  {
    duration: "5h 10m",
    description:
      "Learn Procreate Animation: Create Engaging Animated Letters on the iPad",
    stdcount: "234",
    link: "#",
    img: project3,
    by: "Lucas Ridley",
  },
];

const Carousel = ({ updateModal, humansVideos, setSelectedVideo }) => {
  const itemRef = useRef([]);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(0);
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === "prev") {
      return currentIndex <= 0;
    }

    if (direction === "next" && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  useEffect(() => {
    setData(humansVideos);
    setCategories(samplecategories);
  }, [humansVideos]);

  useEffect(() => {
    const currentTab = itemRef.current[selected];
    console.log(currentTab);
    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
  }, [data, selected]);

  return (
    <div className="carousel my-12 mx-auto mt-[60px] md:mt-[70px] hidden">
      <div className="mb-[25px] flex justify-center w-full">
        <div className="flex justify-between items-center w-[87%] flex-col">
          <h2 className="font-avenir-black text-white text-[24px] md:text-[40px] font-bold mb-[50px] md:text-center">
            Lifelong Learning from our Podcasats
          </h2>
          <div className="w-full flex justify-between flex-row-reverse md:flex-row">
            <div className="flex items-center w-full overflow-scroll ml-[30px] md:ml-0">
              <div
                className={`relative flex gap-[30px] items-center h-fit ${
                  categories.length < 1 && "animate-pulse"
                }`}
              >
                {categories.length > 0
                  ? categories.map((category, i) => (
                      <div
                        ref={(el) => (itemRef.current[i] = el)}
                        className="flex flex-col cursor-pointer mb-2"
                        onClick={() => {
                          setSelected(i);
                        }}
                      >
                        <p
                          className={`text-[16px] font-sans whitespace-nowrap transition-all duration-300 font-medium ${
                            categories[selected] === category
                              ? "text-[#DF5B04]"
                              : "text-[#B8B8B8]"
                          }`}
                        >
                          {category}
                        </p>
                      </div>
                    ))
                  : [...Array(8).keys()].map(() => (
                      <div className="w-[100px] h-[16px] bg-[#202020]"></div>
                    ))}
                <span
                  className={`absolute block h-1 bg-[#DF5B04] border-[#DF5B04] border-[2px] bottom-0 transition-all duration-300 !w-[25px] ${
                    categories.length < 1 && "hidden"
                  }`}
                  style={{ left: tabUnderlineLeft }}
                />
              </div>
            </div>
            <img src={filter} alt="filter" className="md:ml-[30px] ml-0" />
          </div>
        </div>
      </div>

      <div className="relative overflow-hidden flex justify-center">
        {/* <div className="flex justify-between absolute top left w-[87%] h-full">
          <button
            onClick={movePrev}
            className="text-white w-10 h-full text-center z-10 p-0 ml-[-15px] transition-all ease-in-out duration-300"
          >
            <img src={leftslide} alt="leftslide" />
          </button>
          <button
            onClick={moveNext}
            className="text-white w-10 h-full text-center z-10 p-0 mr-[-15px] transition-all ease-in-out duration-300"
          >
            <img src={rightslide} alt="rightslide" />
          </button>
        </div> */}
        <Slider
          slidesToShow={4}
          wheelScroll={1}
          wheel
          // shift={25}
          className={`h-[320px] w-[87%] gap-[24px] relative ${
            data.length < 1 && "animate-pulse"
          }`}
          nextArrow={
            <button
              onClick={moveNext}
              className="text-white w-10 text-center z-10 p-0 mr-[-15px] transition-all ease-in-out duration-300"
            >
              <img src={rightslide} alt="rightslide" />
            </button>
          }
          prevArrow={
            <button
              onClick={movePrev}
              className="text-white w-10 text-center z-10 p-0 ml-[-15px] transition-all ease-in-out duration-300"
            >
              <img src={leftslide} alt="leftslide" />
            </button>
          }
        >
          {data.length > 0
            ? data.map((video, index) => {
                return (
                  <VideoThumbnailCard
                    data={video}
                    updateModal={updateModal}
                    key={video.id}
                    setSelectedVideo={setSelectedVideo}
                  />
                );
              })
            : [...Array(15).keys()].map((index) => (
                <div key={index} className="!w-[288px] h-[320px] bg-[#202020]">
                  <div className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0">
                    <div className="top-0 absolute left-0 w-full h-full"></div>
                  </div>
                </div>
              ))}
        </Slider>
      </div>

      <div className="my-[70px] flex justify-center">
        <Link to="/videocast">
          <button className="bg-[#DF5B04] hover:bg-[#222222] transition-colors text-white font-semibold flex items-center px-[2rem] min-h-[4rem]">
            See More Podcasts{" "}
            <img src={arrowLeft} alt="arrow" className="ml-[10px]" />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Carousel;
