import React from "react";
import { useParams, Link } from "react-router-dom";
import arrowLeft from "../assets/Arrow_drop_right.png";

function OrderDetails() {
  const params = useParams();
  return (
    <div className="flex justify-center pt-[104px] md:pt-[204px] font-opensans tracking-wider">
      <div className="w-full md:w-[67%] bg-[#222222] md:p-[25px] h-[300px] flex-col md:flex-row text-center">
        <div className="w-full flex justify-center items-center text-white mt-[30px]">
          Order ID : <span className="uppercase"> {params.order_number}</span>
        </div>
        <div className="w-full flex justify-center items-center text-white mt-[10px]">
          Thank you, your speaking book session is confirmed.
        </div>
        <div className="w-full flex justify-center items-center text-white mt-[10px]">
          Please check your mail for more info.
        </div>
        <div className="w-full flex justify-center items-center text-white mt-[10px]">
          <Link
            to="/"
            className="font-avenir-black bg-[#DF5B04] hover:bg-[#121212] transition-colors text-white font-semibold flex items-center px-[2rem] min-h-[4rem] w-[13rem] mt-[15px]"
          >
            Go to Home
            <img src={arrowLeft} alt="arrow" className="ml-[10px]" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
