import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const timeStamps = ["8:30am - 9:30am", "10:00am - 11:00am", "12:30pm - 1:30pm"];

const VideoModal = ({ showModal, updateModal, selectedVideo }) => {
  return (
    <>
      {showModal ? (
        <>
          <div
            className="backdrop-blur-sm flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50 font-opensans tracking-wider h-full md:h-auto"
            onClick={() => updateModal(false)}
          >
            <div className="relative w-[90%] md:w-auto my-6 mx-auto max-w-full h-auto">
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-[#222222] outline-none focus:outline-none md:px-[25px] md:pb-[25px] pt-[50px]">
                <button
                  className="bg-transparent border-0 text-black float-right absolute top-[15px] right-[15px]"
                  onClick={() => updateModal(false)}
                >
                  <span className="absolute right-[15px] h-[24px] w-[2px] bg-[#B8B8B8] rotate-45"></span>
                  <span className="absolute right-[15px] h-[24px] w-[2px] bg-[#B8B8B8] rotate-[-45deg]"></span>
                </button>
                <iframe
                  title="Youtube"
                  src={`https://www.youtube.com/embed/${selectedVideo.youtube_video_id}`}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  className="md:min-w-[700px] aspect-video"
                ></iframe>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default VideoModal;
