import React from "react";
import location from "../../assets/svg/location.svg";
import ticket from "../../assets/svg/ticket.svg";
import { Link } from "react-router-dom";

function EventBox({ data }) {
  return (
    <div className="relative w-full flex flex-col justify-evenly bg-[#222222]">
      <div
        className="h-full w-full block z-0"
        // style={{
        //   backgroundImage: `url(${data.img || ""})`,
        // }}
      >
        <img
          src={`${process.env.REACT_APP_BASE_URL}/${data.banner_img}`}
          className="object-cover w-full h-[175px]"
        />

        {/* <div className="top-0 absolute left-0 w-full h-[175px] bg-gradient-to-t from-[#1B1C20] to-[#222222] opacity-30"></div> */}
        <div className="top-0 absolute left-0 w-full h-[175px] bg-gradient-to-t from-[#1B1C20]"></div>

        <div className="text-white text-[18px] font-bold text-start p-[15px] w-full">
          <div className="flex flex-col justify-between h-full w-full">
            <div>
              <h2 className="text-[16px] text-white font-bold mb-[10px] w-full md:w-full text-ellipsis overflow-hidden projecttitle">
                {data.name}
              </h2>
              <h6 className="text-[#B8B8B8] font-medium text-[14px]">
                {data.small_description}
              </h6>

              <div className="my-[20px]">
                <div className="flex items-center mb-[10px]">
                  <img src={location} alt="" className="w-[20px] h-[20px]" />
                  <h6 className="ml-[10px] text-[#B8B8B8] font-medium text-[14px]">
                    {data.location}
                  </h6>
                </div>
                <div className="flex items-center">
                  <img src={ticket} alt="" className="w-[20px] h-[20px]" />
                  <h6 className="ml-[10px] text-[#B8B8B8] font-medium text-[14px]">
                    {data.ticket_price_range}
                  </h6>
                </div>
              </div>
            </div>
            <Link to={`/events/${data.slug}`}>
              <button className="bg-[#DF5B04] w-full hover:bg-[#121212] transition-colors text-white font-semibold flex justify-center items-center px-[2rem] min-h-[4rem]">
                Buy Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventBox;
