import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ScrollToTop from "./components/ScrollToTop";
import Events from "./pages/Events";
import EventDetails from "./pages/EventDetails";
import HumanDetails from "./pages/HumanDetails";
import MyAccount from "./pages/MyAccount";
import VideoCast from "./pages/VideoCast";
import OrderDetails from "./pages/OrderDetails";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/myaccount" exact element={<MyAccount />} />
          <Route path="/videocast" exact element={<VideoCast />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:event_slug" element={<EventDetails />} />
          <Route
            path="/events/:event_slug/:human_slug"
            element={<HumanDetails />}
          />
          <Route
            path="/order-confirmation/:order_number"
            element={<OrderDetails />}
          />
        </Routes>
      </ScrollToTop>
      <Footer />
    </BrowserRouter>
    // <div className="App  font-Avenir">
    //   <Header />
    //   <Home />
    //   <Footer />
    // </div>
  );
}

export default App;
