import React from "react";
import reactangle from "../../assets/Rectangle.png";
import arrowLeft from "../../assets/Arrow_drop_right.png";
function Instructor() {
  return (
    <div className="w-full flex justify-center mt-[70px] bg-[#222222] xl:bg-[transparent]">
      <div className="xl:w-[87%] w-full h-[400px] xl:bg-[#222222] flex justify-center xl:justify-between overflow-hidden relative">
        <div className="w-[87%] xl:w-full xl:w-1/2 flex flex-col items-center justify-start py-[50px] xl:px-[100px]">
          <h2 className="font-avenir-black text-[24px] xl:text-[40px] font-bold text-white text-left mb-[15px] w-full">
            Become a Speaking Book
          </h2>
          <p className="text-[16px] text-[#B0B0B0] text-left font-sans">
            Do you have an interesting story, experience, or perspective to
            share? Join our growing collection of speaking books!
          </p>
          <div className="w-full mt-[50px]">
            <a
              href="https://sprw.io/stt-121cc8"
              target="_blank"
              rel="noopener noreferrer"
              className="font-avenir-black bg-[#DF5B04] hover:bg-[#121212] transition-colors text-white font-semibold flex items-center px-[2rem] min-h-[4rem] min-w-[2rem] w-[8rem]"
            >
              Apply
              <img src={arrowLeft} alt="arrow" className="ml-[10px]" />
            </a>
          </div>
        </div>
        <div className="h-[650px] w-[90px] bg-[#DF5B04] rotate-[-45deg] xl:rotate-[-38deg] mt-[-140px] right-[-170px] xl:right-[450px] absolute" />

        <img src={reactangle} alt="" className="hidden xl:block" />
      </div>
    </div>
  );
}

export default Instructor;
