import React, { useRef, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function ListItem({ category, value, setValue, keyIndex, setShowLogoutModal }) {
  return (
    <div
      key={keyIndex}
      className={`flex flex-col cursor-pointer ${
        keyIndex !== 0 && "mt-[25px]"
      }`}
      onClick={() => {
        setValue(keyIndex);
        if (category === "Logout") {
          setShowLogoutModal(true);
        }
      }}
    >
      <p
        className={`text-[16px] font-medium font-sans ${
          value === keyIndex ? "text-[#DF5B04]" : "text-[#B8B8B8]"
        }`}
      >
        {category}
      </p>

      <hr
        className={`w-[25px] ${
          value === keyIndex
            ? "bg-[#DF5B04] border-[#DF5B04]"
            : "bg-[#222222] border-[#222222]"
        } border-[2px] mt-[5px]`}
      />
    </div>
  );
}

function ButtonItem({
  data,
  value,
  setValue,
  keyIndex,
  updateRef,
  setShowLogoutModal,
}) {
  return (
    <div
      ref={(el) => updateRef(keyIndex, el)}
      className="flex flex-col cursor-pointer mb-2"
      onClick={() => {
        if (data === "Logout") {
          setShowLogoutModal(true);
        }
        setValue(keyIndex);
      }}
    >
      <p
        className={`text-[16px] font-sans whitespace-nowrap transition-all duration-300 font-medium ${
          value === keyIndex ? "text-[#DF5B04]" : "text-[#B8B8B8]"
        }`}
      >
        {data}
      </p>
    </div>
  );
}

function Sidebar({ data, value, setValue }) {
  const { logout } = useAuth0();
  const itemRef = useRef([]);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const updateRef = (key, el) => {
    itemRef.current[key] = el;
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 250) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  });

  useEffect(() => {
    const currentTab = itemRef.current[value];
    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
  }, [data, value]);

  return (
    <>
      {/* <select
        id="countries"
        class="bg-[#222222] text-[16px] text[#B8B8B8] block w-full dark:bg-[#222222] dark:placeholder-[16px] appearance-none dark:text-[#B8B8B8] bg-[#222222] lg:mr-[12px] px-[30px] py-[20px] lg:mb-0 mb-[30px] lg:hidden"
      >
        {Array.isArray(data)
          ? data.map((category, index) => (
              <option value={category} selected={index === 1}>
                {category}
              </option>
            ))
          : Object.keys(data).map((type, index) => (
              <optgroup label={type.toUpperCase()}>
                {data[type].map((category) => (
                  <option value={category} selected={index === 1}>
                    {category}
                  </option>
                ))}
              </optgroup>
            ))}
      </select> */}

      <div
        className={`flex justify-center items-center w-full bg-[#121212] ${
          scrolled
            ? "fixed top-[86px] left-0 pb-[10px] z-10"
            : "w-full relative"
        }`}
      >
        <div
          className={`flex justify-between relative gap-[30px] items-center overflow-scroll mb-[30px] lg:hidden ${
            scrolled ? "w-[87%] z-10" : "w-full"
          }`}
        >
          {Array.isArray(data)
            ? data.length > 0
              ? data.map((category, i) => (
                  <ButtonItem
                    data={category}
                    value={value}
                    setValue={setValue}
                    keyIndex={i}
                    updateRef={updateRef}
                    setShowLogoutModal={setShowLogoutModal}
                  />
                ))
              : [...Array(8).keys()].map(() => (
                  <div className="w-[100px] h-[16px] bg-[#202020]"></div>
                ))
            : Object.keys(data).length > 0
            ? Object.keys(data).map((type, i) => (
                <>
                  {data[type].map((cat, i) => (
                    <ButtonItem
                      updateRef={updateRef}
                      data={cat}
                      value={value}
                      setValue={setValue}
                      keyIndex={i}
                      setShowLogoutModal={setShowLogoutModal}
                    />
                  ))}
                </>
              ))
            : [...Array(8).keys()].map(() => (
                <div className="w-[100px] h-[16px] bg-[#202020]"></div>
              ))}
          <span
            className={`absolute block h-1 bg-[#DF5B04] border-[#DF5B04] border-[2px] bottom-0 transition-all duration-300 !w-[25px] ${
              Array.isArray(data)
                ? data.length < 0 && "hidden"
                : Object.keys(data).length < 0 && "hidden"
            }`}
            style={{ left: tabUnderlineLeft }}
          />
        </div>
      </div>

      <div
        className={`bg-[#222222] lg:mr-[12px] p-[30px] lg:mb-0 mb-[30px] hidden lg:block`}
      >
        {Array.isArray(data) ? (
          data.length > 0 ? (
            data.map((category, index) => (
              <ListItem
                category={category}
                value={value}
                setValue={setValue}
                keyIndex={index}
                setShowLogoutModal={setShowLogoutModal}
              />
            ))
          ) : (
            <div className="min-h-600px animate-pulse w-full"></div>
          )
        ) : Object.keys(data).length > 0 ? (
          Object.keys(data).map((type, index) => (
            <>
              <p
                className={`${
                  index !== 0 && "mt-[50px]"
                } text-[16px] font-medium font-sans text-[#B8B8B8] opacity-50 ml-[-10px] capitalize `}
              >
                {type}
              </p>
              {data[type].map((category, i) => (
                <ListItem
                  category={category}
                  value={value}
                  setValue={setValue}
                  keyIndex={index}
                  setShowLogoutModal={setShowLogoutModal}
                />
              ))}
            </>
          ))
        ) : (
          <div className="min-h-600px animate-pulse w-full"></div>
        )}
      </div>
      {showLogoutModal && (
        <div className="backdrop-blur-sm flex justify-center items-center overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-50 font-opensans tracking-wider h-full">
          <div className="relative w-auto my-6 mx-auto max-w-full md:max-w-3xl h-full md:h-auto">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-[#222222] outline-none focus:outline-none p-[30px] md:p-[50px]">
              <button
                className="bg-transparent border-0 text-black float-right absolute top-[15px] right-[15px]"
                onClick={() => setShowLogoutModal(false)}
              >
                <span className="absolute right-[15px] h-[24px] w-[2px] bg-[#B8B8B8] rotate-45"></span>
                <span className="absolute right-[15px] h-[24px] w-[2px] bg-[#B8B8B8] rotate-[-45deg]"></span>
              </button>
              <div className="flex flex-col text-white text-center">
                Are you sure you want to Logout ?
              </div>
              <div className="flex mt-4 gap-4">
                <button
                  className="bg-[#DF5B04] hover:bg-[#121212] py-[15px] px-[60px] text-white font-bold flex justify-center items-center mt-[20px] w-full"
                  onClick={() => setShowLogoutModal(false)}
                >
                  Stay
                </button>

                <button
                  className="bg-[#DF5B04] hover:bg-[#121212] py-[15px] px-[60px] text-white font-bold flex justify-center items-center mt-[20px] w-full"
                  onClick={logout}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Sidebar;
