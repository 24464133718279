import React from "react";
import homebg from "../../assets/home-banner-humans.png";
import arrowLeft from "../../assets/Arrow_drop_right.png";

function Banner() {
  return (
    <div className="z-0">
      <section class="w-full h-[572px]">
        <img
          src={homebg}
          className="object-cover w-full h-full"
          alt="Home bg"
        />
      </section>

      <section class="w-full h-[572px] absolute top-0 left-0 flex justify-center items-center  bg-[#121212] opacity-70"></section>
      <section class="w-full h-[572px] absolute top-0 left-0 flex justify-center items-center flex-col">
        <h2 className="text-white text-[24px] md:text-[40px] font-black font-avenir-black text-center md:leading-[55px]">
          You Are the Book: House of Wisdom's Speaking Library <br />
          {/* <span className="text-[#FB802E]">The Human Library</span> */}
        </h2>
        <h3 className="text-[#B8B8B8] text-[20px] p-[10px] md:p-0 md:text-[24px] text-center mt-[25px] mb-[50px] font-normal">
          A Space for the public to converse one-on-one with some of the world's
          most interesting people.
        </h3>
        <button
          className="bg-[#DF5B04] hover:bg-[#222222] transition-colors text-white font-semibold flex items-center px-[2rem] min-h-[4rem]"
          onClick={() =>
            document.getElementById("featured").scrollIntoView({
              behavior: "smooth",
            })
          }
        >
          Read More <img src={arrowLeft} alt="arrow" className="ml-[10px]" />
        </button>
      </section>
    </div>
  );
}

export default Banner;
