import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Logo.png";

function Logo() {
  return (
    <Link to="/">
      <img src={logo} alt="Human Library" className="max-w-[100px]" />
    </Link>
  );
}

export default Logo;
