import React from "react";
import { Link, useParams } from "react-router-dom";

function CategoryCard({ card }) {
  const params = useParams();
  return (
    <div className="carousel-item relative h-[360px] snap-start">
      <Link
        to={`/events/${params.event_slug}/${card.slug}`}
        className="h-full w-full aspect-square block bg-origin-padding bg-left-top bg-cover bg-no-repeat z-0"
        style={{
          backgroundImage: `url(${`${process.env.REACT_APP_BASE_URL}/${card.img}`})`,
        }}
      >
        <div className="top-0 absolute left-0 w-full h-full bg-gradient-to-t from-[#1B1C20]"></div>
        <div className="bottom-0 absolute text-white text-[18px] font-bold text-start px-[25px] py-[25px]">
          <h2 className="text-[32px] font-avenir-black max-w-[100px]">
            {card.name}
          </h2>
          {/* <h2 className="text-[#B8B8B8] font-semibold">{card.by}</h2> */}
        </div>
      </Link>
    </div>
  );
}

export default CategoryCard;
