import React from "react";
import { useState } from "react";
import Sidebar from "../components/Category/Sidebar";
import ListPaginate from "../components/ListPaginate";
import AccountSetting from "../components/MyAccount/AccountSetting";

const settings = ["My Profile", "My Orders", "Logout"];

function MyAccount() {
  const [selected, setSelected] = useState(0);
  return (
    <div className="pt-[86px]">
      {" "}
      <div className="w-full justify-center flex lg:hidden">
        <div id="human-mobile" className="w-[87%] mt-[50px]">
          <Sidebar data={settings} value={selected} setValue={setSelected} />
          {selected === 0 ? (
            <div className="w-full flex-col items-center flex justify-center">
              {" "}
              <form className="w-1/2">
                <div class="mb-6">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-white dark:text-gray-300"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#202020] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    placeholder="Your email address"
                    required=""
                  />
                </div>
                <div class="mb-6">
                  <label
                    for="password"
                    class="block mb-2 text-sm font-medium text-white dark:text-gray-300"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#202020] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required=""
                  />
                </div>

                <div class="mb-6">
                  <label
                    for="email"
                    class="block mb-2 text-sm font-medium text-white dark:text-gray-300"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#202020] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    placeholder="Your email address"
                    required=""
                  />
                </div>
                <div class="mb-6">
                  <label
                    for="text1"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    data1
                  </label>
                  <input
                    type="text"
                    id="text1"
                    class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-[#202020] dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    required=""
                  />
                </div>

                <button
                  type="submit"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save Changes
                </button>
              </form>
            </div>
          ) : (
            <ListPaginate renderCard="orders" list={[...Array(1500).keys()]} />
          )}
          {/* {selected === 1 && <Orders />} */}
        </div>
      </div>
      <div className="w-full justify-center hidden lg:flex">
        <div id="human-desktop" className="w-[87%] flex">
          <div className="w-1/4">
            <Sidebar data={settings} value={selected} setValue={setSelected} />
          </div>
          <div className="w-3/4">
            {selected === 0 ? (
              <AccountSetting />
            ) : (
              <ListPaginate
                renderCard="orders"
                list={[...Array(1500).keys()]}
              />
            )}
            {/* {selected === 1 && <Orders />} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
